import { useState } from "react";

import { AuthFooter, AuthHeader, AuthHeaderWrapper } from "@shared/components";
import { useLocation } from "@shared/react-router-dom";
import type { StorePlatform } from "@shared/types/StorePlatform";
import { authRoutes } from "@unauthenticated/shared/data/authRoutes";
import {
  Page,
  PageContent,
  LinkExpiredNotificationPage,
  LinkNotificationPage,
} from "@unauthenticated/signupSignin/components";

import { EmailFormContent } from "./EmailFormContent";
import { SuccessContent } from "./SuccessContent";

import * as styles from "./ContinueWithoutPassword.styles";

interface HistoryState {
  source?: "RX_SIGN_IN";
  email?: string;
  page?: "success";
}

const ContinueWithoutPasswordPage = () => {
  const [email, setEmail] = useState(undefined);
  const { state }: { state: HistoryState } = useLocation();
  const [page, setPage] = useState<"email" | "success">(state?.page || "email");
  const isSwiftFillSignIn = state?.source === "RX_SIGN_IN";

  const { search } = useLocation();

  const linkEmail = new URLSearchParams(search).get("email");
  const linkHasError = new URLSearchParams(search).get("has_error") === "true";
  const linkStorePlatform = new URLSearchParams(search).get(
    "enable_shared_account"
  ) as StorePlatform;

  if (linkEmail && linkStorePlatform) {
    if (linkHasError) {
      return <LinkExpiredNotificationPage email={linkEmail} storePlatform={linkStorePlatform} />;
    }

    return <LinkNotificationPage email={linkEmail} storePlatform={linkStorePlatform} />;
  }

  const renderContent = () => {
    if (page === "email") {
      return <EmailFormContent email={email} setEmail={setEmail} setPage={setPage} />;
    } else {
      return (
        <SuccessContent
          email={state?.email || email}
          setPage={setPage}
          isSwiftFillSignIn={isSwiftFillSignIn}
        />
      );
    }
  };

  return (
    <Page>
      <PageContent>
        <AuthHeaderWrapper>
          <AuthHeader route={authRoutes.login} />
        </AuthHeaderWrapper>
        <div css={styles.contentLayout}>{renderContent()}</div>
      </PageContent>
      <AuthFooter />
    </Page>
  );
};

export { ContinueWithoutPasswordPage };
