import { css, type Theme } from "@emotion/react";

import { timing, animations } from "@styles";

export const boxLink = css`
  padding: 0.75rem;
  margin-bottom: 0.5rem;

  &:active {
    .icon-right {
      margin-left: 2rem;
    }
  }
`;

export const stethoscope = (theme: Theme) => css`
  margin-right: 1rem;
  color: ${theme.success.textBase};
`;

export const arrowIcon = (theme: Theme) => css`
  margin-left: 1rem;
  color: ${theme.success.textBase};
  transition: margin ${timing.fast} ${animations.easeOutCubic};
`;
