import type { MutationHookOptions } from "@apollo/client";
import { useMutation, gql } from "@apollo/client";

const ANALYTICS_UPDATE_PROFILE_MUTATION = gql`
  mutation AnalyticsUpdateProfile_Shared_Mutation($input: AnalyticsUpdateProfileInput!) {
    analyticsUpdateProfile(input: $input) {
      errors {
        message
      }
    }
  }
`;

interface AnalyticsUpdateProfileError {
  message: string;
}

interface AnalyticsUpdateProfileData {
  analyticsUpdateProfile: {
    errors: AnalyticsUpdateProfileError;
  };
}

interface Variables {
  input: {
    payload: {
      utm_source?: string;
      utm_campaign?: string;
      utm_medium?: string;
    };
  };
}

const useAnalyticsUpdateProfileMutation = (
  options?: MutationHookOptions<AnalyticsUpdateProfileData, Variables>
) => useMutation<AnalyticsUpdateProfileData, Variables>(ANALYTICS_UPDATE_PROFILE_MUTATION, options);

export type { AnalyticsUpdateProfileData };
export { useAnalyticsUpdateProfileMutation, ANALYTICS_UPDATE_PROFILE_MUTATION };
