import type { Theme } from "@emotion/react";
import { css } from "@emotion/react";
import { typography } from "aviary-tokens";

import { dimensions } from "@styles";

export const mainContainer = css`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  justify-content: center;
  align-items: center;
  padding: 8.75rem 2rem;
`;

export const resentLink = (theme: Theme) => css`
  color: ${theme.text.body};
`;
export const headerContainer = css`
  display: flex;
  max-width: 40rem;
  flex-direction: column;
  gap: 2.5rem;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const descriptionContainer = css`
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const dualLogoIcon = css`
  height: 5.4rem;
`;
export const semiBold = css`
  font-weight: ${typography.weightStrong};
  @media only screen and (max-width: ${dimensions.tabletMax}) {
    font-weight: ${typography.weightBase};
  }
`;
