import { css } from "@emotion/react";

import { dimensions } from "@styles";

export const header = css`
  width: 100%;
`;

export const svgContainer = css`
  width: 9rem;
  height: 2.15rem;
`;

export const emersonLogo = css`
  height: 2.75rem;
  object-fit: contain;

  @media (max-width: ${dimensions.phoneLargeMax}) {
    height: 4rem;
  }
`;

export const logoWrapper = {
  base: css`
    padding: 1rem;

    @media screen and (max-width: ${dimensions.phoneLargeMax}) {
      padding: 1rem 1rem 0;
    }
  `,
  fromCompanyByFullscript: css`
    width: 100%;
    display: flex;
    justify-content: space-between;

    @media (max-width: ${dimensions.phoneLargeMax}) {
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }
  `,
};
