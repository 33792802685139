import { faChevronLeft } from "@fortawesome/pro-regular-svg-icons";
import { type SyntheticEvent, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";

import { Arrange, Link, Typography, useToast } from "@aviary";
import FsEeDualLogoIcon from "@shared/assets/images/fs-ee-dual-logo-icon.svg";
import { AuthHeader, AuthHeaderWrapper } from "@shared/components";
import { useBreakpoints } from "@shared/hooks";
import { FontAwesomeIcon } from "@shared/react-fontawesome";
import type { StorePlatform } from "@shared/types/StorePlatform";
import { gRecaptchaExecute } from "@shared/utils";
import { authRoutes } from "@unauthenticated/shared/data/authRoutes";
import {
  useRequestSharedAccountVerification,
  type RequestSharedAccountVerificationEmailData,
  type RequestSharedAccountVerificationEmailError,
} from "@unauthenticated/signupSignin/data/mutations";
import { l } from "@unauthenticated/signupSignin/locales/i18n";

import { PageContent, Page } from "../../components";

import * as styles from "./LinkExpiredNotificationPage.styles";

interface Props {
  email: string;
  storePlatform: StorePlatform;
}

const LinkExpiredNotificationPage = ({ email, storePlatform }: Props) => {
  const { t } = useTranslation();
  const { tablet } = useBreakpoints();
  const isDesktop = tablet.greaterThan;
  const { makeToast } = useToast();
  const [requestSharedAccountVerification] = useRequestSharedAccountVerification({
    onCompleted: (data: RequestSharedAccountVerificationEmailData) => {
      const isSuccessful = data?.auth?.requestSharedAccountVerificationEmail?.sent;
      if (!isSuccessful) {
        makeToast("error", t(l.linkNotification.errorResendLink));
      }
    },
    onError: (data: RequestSharedAccountVerificationEmailError) => {
      makeToast("error", data?.message || t(l.linkNotification.errorResendLink));
    },
  });
  const query = () => {
    makeToast("success", t(l.linkNotification.newResendLink));
    gRecaptchaExecute(gToken => {
      requestSharedAccountVerification({
        variables: {
          input: {
            email,
            requestedPlatform: storePlatform,
            captchaToken: gToken,
          },
        },
      });
    });
  };

  useEffect(() => {
    query();
  }, []);

  const resendLink = (e: SyntheticEvent) => {
    e.preventDefault();
    query();
  };

  const platform = () => (storePlatform === "Fullscript" ? "fs" : "ee");
  const renderResendLink = () => (
    <Link onClick={resendLink} css={styles.inlineLink} data-testid="resend-link">
      {t(l.linkNotification.resend)}
    </Link>
  );

  return (
    <Page data-e2e="link-expired-notification">
      <AuthHeaderWrapper includeBorder>
        <AuthHeader
          route={storePlatform === "Fullscript" ? authRoutes.login : authRoutes.emerson.login}
          showFromEmerson={storePlatform === "Emerson"}
        />
      </AuthHeaderWrapper>
      <PageContent css={styles.notificationWrapper}>
        <Arrange justify="center" alignment="center" isFullWidth isFullHeight isVertical>
          <Typography isFullWidth type={isDesktop ? "h1" : "h3"}>
            {t(l.linkNotification.newSignInLink)}
          </Typography>
          <Typography type={isDesktop ? "h4" : "body"} css={styles.semibold}>
            {t(l.linkNotification.pleaseCheck[platform()])}
          </Typography>
          <Arrange justify="center" isFullWidth>
            <img
              css={styles.dualLogoIcon}
              src={FsEeDualLogoIcon}
              alt="Fullscript Emerson Ecologics logo"
            />
          </Arrange>
          <Typography type="body" css={styles.bodySpacing}>
            <Trans
              i18nKey={l.linkNotification.emailedTo}
              values={{ email }}
              components={{ bold: <strong /> }}
            />
          </Typography>
          <Typography type="body">
            <Trans
              i18nKey={l.linkNotification.didntGetEmail}
              components={{
                1: renderResendLink(),
              }}
            >
              {renderResendLink()}
            </Trans>
          </Typography>
          <Link
            href={
              storePlatform === "Fullscript"
                ? authRoutes.practitioner_sign_up
                : authRoutes.emerson.sign_up
            }
            css={styles.noStyleLink}
          >
            <FontAwesomeIcon
              className="icon-chevron"
              icon={faChevronLeft}
              size="sm"
              css={styles.chevron}
            />
            {t(l.linkNotification.returnToSignUp)}
          </Link>
        </Arrange>
      </PageContent>
    </Page>
  );
};

export { LinkExpiredNotificationPage };
