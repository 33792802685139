import ReactRailsUJS from "@shared/polyfill/react_ujs";
import { initSentry, hello } from "@shared/utils";

const componentRequireContext = require.context("@unauthenticated/signupSignin", false);

initSentry({ enableRouterInstrumentation: true, tracesSampleRate: 0.01 });

// eslint-disable-next-line react-hooks/rules-of-hooks
ReactRailsUJS.useContext(componentRequireContext);
hello();
