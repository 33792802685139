import type { NormalizedCacheObject } from "@apollo/client";
import { ApolloClient, ApolloLink } from "@apollo/client";
import { useMemo } from "react";

import {
  getErrorLink,
  getUploadLink,
  getQueryLoadingLink,
  getPersistedQueriesLink,
} from "@shared/data/client";
import { useFlippers } from "@shared/hooks";
import { isDevelopment, isProduction } from "@shared/utils";

import { getCache } from "./cache";

const useClient = (): ApolloClient<NormalizedCacheObject> => {
  const [gqlPersistedQueriesFlipper] = useFlippers("gql_persisted_queries");

  const getClient = () => {
    const getLink = () => {
      const errorLink = getErrorLink(null, true);
      const uploadLink = getUploadLink();

      let links = [errorLink];
      if (gqlPersistedQueriesFlipper) {
        const persistedQueriesLink = getPersistedQueriesLink();
        links.push(persistedQueriesLink);
      }
      if (isDevelopment()) {
        const queryLoadingLink = getQueryLoadingLink();
        links = [...links, queryLoadingLink];
      }

      return ApolloLink.from([...links, uploadLink]);
    };

    const cache = getCache();

    return new ApolloClient({
      link: getLink(),
      cache,
      connectToDevTools: !isProduction(),
    });
  };

  return useMemo(getClient, [gqlPersistedQueriesFlipper]);
};

export { useClient };
