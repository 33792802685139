import { useState } from "react";

import { AuthHeader, AuthHeaderWrapper, AuthFooter } from "@shared/components";
import { useSharedGlobalConfig } from "@shared/hooks";
import { Navigate } from "@shared/react-router-dom";
import { TwoFactorAuthenticationMethods } from "@shared/types/graphqlGenerated.d";
import { SignInBlock, TwoFactorAuthenticationForm } from "@unauthenticated/shared/components";
import { authRoutes } from "@unauthenticated/shared/data/authRoutes";
import { PageContent, Page } from "@unauthenticated/signupSignin/components";

interface Props {
  additionalAttributes?: any;
}

const EmersonSignInPage = ({ additionalAttributes }: Props) => {
  const { isUS } = useSharedGlobalConfig();

  const [isShowTwoFactorFlow, setIsShowTwoFactorFlow] = useState(false);
  const [loginEmail, setLoginEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [twoFactorAuthToken, setTwoFactorAuthToken] = useState("");
  const [isLockedOut, setIsLockedOut] = useState(false);
  const [userOtpMethod, setUserOtpMethod] = useState<TwoFactorAuthenticationMethods>(
    TwoFactorAuthenticationMethods.Email
  );

  if (!isUS) return <Navigate to={authRoutes.login} />;

  const handleShowTwoFactorFlow = ({ otpMethod, userEmail, phoneLast4, authToken }) => {
    setIsShowTwoFactorFlow(true);
    setTwoFactorAuthToken(authToken);
    setUserOtpMethod(otpMethod);
    setLoginEmail(userEmail);
    setPhone(phoneLast4);
  };

  const handleBackToSignIn = () => {
    setIsShowTwoFactorFlow(false);
  };

  const handleUserLockedOut = () => {
    setIsLockedOut(true);
    handleBackToSignIn();
  };

  const renderSignInForm = () => {
    if (isShowTwoFactorFlow) {
      return (
        <TwoFactorAuthenticationForm
          userEmail={loginEmail}
          phoneLast4={phone}
          otpMethod={userOtpMethod}
          twoFactorAuthToken={twoFactorAuthToken}
          onBackToSignIn={handleBackToSignIn}
          onUserLockedOut={handleUserLockedOut}
        />
      );
    }

    return (
      <SignInBlock
        additionalAttributes={additionalAttributes}
        onShowTwoFactorFlow={handleShowTwoFactorFlow}
        isLockedOut={isLockedOut}
      />
    );
  };

  return (
    <Page data-e2e="sign-in-page">
      <AuthHeaderWrapper includeBorder>
        <AuthHeader showFromEmerson />
      </AuthHeaderWrapper>
      <PageContent>
        {renderSignInForm()}
        <AuthFooter />
      </PageContent>
    </Page>
  );
};

export { EmersonSignInPage };
