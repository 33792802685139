import { css } from "@emotion/react";

import { dimensions } from "@styles";

export const form = css`
  width: 100%;
  max-width: unset;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: unset;

  @media (max-width: ${dimensions.phoneMax}) {
    max-width: 25.91rem;
  }
`;

export const marginBottom = css`
  margin-bottom: 1rem;
`;

export const serviceTerms = css`
  margin-bottom: 1rem;
  padding: 0.5rem;
`;

export const Row = css`
  display: flex;
  width: 100%;
  ${marginBottom};

  @media (max-width: ${dimensions.phoneMax}) {
    flex-direction: column;
  }
`;

export const margin = css`
  margin-right: 1rem;

  @media (max-width: ${dimensions.phoneMax}) {
    margin-right: 0;
    ${marginBottom};
  }
`;

export const fieldset = css`
  border: none;
  width: 100%;
`;

export const appleButton = css`
  margin-top: 1rem;
`;

export const formWrapper = css`
  padding-top: 2rem;
`;
