import { css } from "@emotion/react";

import { dimensions } from "@styles";

export const container = css`
  padding: 2rem;
  @media (max-width: ${dimensions.phoneLargeMax}) {
    overflow-y: visible;
  }
`;
