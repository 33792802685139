import { useTranslation } from "react-i18next";

import { AppleSSOButton, GoogleSSOButton, MagicLinkButton } from "@shared/components";
import { FormWrapper, SignInWrapper } from "@unauthenticated/shared/components";
import { SSOErrorBox } from "@unauthenticated/shared/components/SSOErrorBox/SSOErrorBox";
import { useTreatmentPlanTerm } from "@unauthenticated/shared/hooks/useTreatmentPlanTerm";
import {
  PatientSignUpForm,
  SignUpContainer,
  SignUpHeader,
  SignUpSubtitle,
  SignUpTitle,
  StoreLandingLayout,
  StoreLogo,
  StoreOffersPill,
} from "@unauthenticated/signupSignin/components";
import type { SubmitValues } from "@unauthenticated/signupSignin/components/PatientSignUpForm";
import { usePatientSignUpMutation } from "@unauthenticated/signupSignin/data/mutations";
import type { PatientAttributes } from "@unauthenticated/signupSignin/data/types";
import { useLogPatientSignUp } from "@unauthenticated/signupSignin/hooks";
import { l } from "@unauthenticated/signupSignin/locales/i18n";

import * as styles from "./PatientStoreActivationPage.styles";

interface Props {
  patientAttributes: PatientAttributes;
  additionalAttributes?: any;
  csuid: string;
  pageVersion: "react_with_sso" | "react";
  ssoErrors?: string;
  storeId?: string;
}

const PatientStoreActivationPage = ({
  patientAttributes,
  additionalAttributes,
  csuid,
  pageVersion,
  ssoErrors,
  storeId,
}: Props) => {
  const { t } = useTranslation();
  const treatmentPlanTerm = useTreatmentPlanTerm();

  const SIGN_UP_PAGE = "rx_patient_sign_up_page";

  const {
    storeLogo,
    storeName,
    firstName,
    storeSlug,
    treatmentPlanSlug,
    labRecommendationId,
    pracName,
    uuid,
  } = patientAttributes;

  const [signUp, { loading, data }] = usePatientSignUpMutation({
    onCompleted: payload => {
      if (payload.auth.patientSignUpFromRx.redirectPath) {
        window.location.assign(payload.auth.patientSignUpFromRx.redirectPath);
      }
    },
  });

  const { logField } = useLogPatientSignUp({
    eventAggregateId: csuid,
    signUpPage: SIGN_UP_PAGE,
    pageVersion,
  });

  const handleSubmit = (values: SubmitValues) => {
    const { gToken, ...attributesFromForm } = values;
    const attributes = {
      ...attributesFromForm,
      treatmentPlanSlug,
      labRecommendationId,
      storeSlug,
      uuid,
      optional: additionalAttributes,
    };
    signUp({ variables: { input: { attributes, captchaToken: gToken } } });
  };

  const signupModalSubtitle = labRecommendationId
    ? t(l.patientRxAuth.SignUpWelcomeMessageLabRecommendation, {
        firstName,
        pracName,
      })
    : t(l.patientRxAuth.SignUpWelcomeMessage, {
        firstName,
        treatmentPlanTerm,
        pracName,
      });

  const renderActivationForm = () => (
    <>
      <SignUpHeader>
        <StoreLogo storeLogo={storeLogo} storeName={storeName} />
        <SignUpTitle title={storeName} />
        <SignUpSubtitle subtitle={signupModalSubtitle} />
        {!labRecommendationId && <StoreOffersPill patientAttributes={patientAttributes} />}
      </SignUpHeader>
      <SignUpContainer>
        <FormWrapper>
          <SignInWrapper>
            <SSOErrorBox ssoErrors={ssoErrors} />
            <GoogleSSOButton
              storeSlug={storeSlug}
              treatmentPlanSlug={treatmentPlanSlug}
              labRecommendationId={labRecommendationId}
              signUpPage={SIGN_UP_PAGE}
              pageVersion={pageVersion}
              uuid={uuid}
            />
            <AppleSSOButton
              css={styles.appleButton}
              storeSlug={storeSlug}
              treatmentPlanSlug={treatmentPlanSlug}
              labRecommendationId={labRecommendationId}
              signUpPage={SIGN_UP_PAGE}
              pageVersion={pageVersion}
              uuid={uuid}
            />
            <MagicLinkButton buttonStyles={styles.appleButton} isOrTextRendered storeId={storeId} />
            <PatientSignUpForm
              onSubmit={handleSubmit}
              isLoading={loading}
              errors={data?.auth?.patientSignUpFromRx?.errors?.fields}
              pageVersion={pageVersion}
              signUpPage={SIGN_UP_PAGE}
              logField={logField}
            />
          </SignInWrapper>
        </FormWrapper>
      </SignUpContainer>
    </>
  );

  return (
    <StoreLandingLayout storeName={storeName} pracAvatar={patientAttributes.pracAvatar}>
      {renderActivationForm()}
    </StoreLandingLayout>
  );
};

export { PatientStoreActivationPage };
