import { css } from "@emotion/react";

import { dimensions } from "@styles";

export const fullWidth = css`
  width: 100%;
`;

export const text = css`
  text-align: center;
`;

export const title = css`
  margin-bottom: 1rem;

  @media (max-width: ${dimensions.phoneLargeMax}) {
    margin-bottom: 0.5rem;
  }
`;

export const marginLeft = css`
  margin-left: 0.5rem;
`;

export const marginTop = css`
  margin-top: 1.5rem;
`;
