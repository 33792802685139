import type { Theme } from "@emotion/react";
import { css } from "@emotion/react";

import { dimensions } from "@styles";

export const header = (theme: Theme) => css`
  display: flex;
  flex-direction: column;
  background-color: ${theme.surface.level1};

  color: ${theme.text.body};

  padding: 0.5rem 0;

  @media screen and (max-width: ${dimensions.phoneLargeMax}) {
    padding: 0;
  }
`;
