import { Spacer } from "@aviary";
import { useLocation } from "@shared/react-router-dom";
import { authRoutes } from "@unauthenticated/shared/data/authRoutes";
import {
  SignUpHeader,
  SignUpTitle,
  StoreLandingLayout,
  StoreLogo,
} from "@unauthenticated/signupSignin/components";
import type { PatientAttributes } from "@unauthenticated/signupSignin/data/types";
import type { LocationType } from "@unauthenticated/signupSignin/data/types/PatientAuth";

import { PasswordForm } from "./PasswordForm";

interface Props {
  patientAttributes: PatientAttributes;
  additionalAttributes?: any;
  ssoErrors?: string;
  storeId?: string;
}

const PatientAuthPasswordPage = ({ patientAttributes, additionalAttributes, storeId }: Props) => {
  const formVersion = "StoreSignInPage";
  const { storeLogo, storeName, treatmentPlanSlug, uuid, storeSlug, pracAvatar } =
    patientAttributes;
  const FORGOT_PASSWORD_LINK = authRoutes.welcome_forgot_password(
    storeSlug,
    uuid || treatmentPlanSlug
  );
  const { state }: LocationType = useLocation();

  return (
    <StoreLandingLayout storeName={storeName} pracAvatar={pracAvatar}>
      <SignUpHeader>
        <StoreLogo storeLogo={storeLogo} storeName={storeName} />
        <SignUpTitle title={storeName} />
      </SignUpHeader>
      <Spacer height="double" />
      <PasswordForm
        additionalAttributes={{ ...additionalAttributes, formVersion }}
        email={state?.email}
        forgotPasswordLink={FORGOT_PASSWORD_LINK}
        storeId={storeId}
      />
    </StoreLandingLayout>
  );
};

export { PatientAuthPasswordPage };
