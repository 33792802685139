import { useState } from "react";
import type { SyntheticEvent } from "react";
import { useTranslation, Trans } from "react-i18next";

import { Button, Column, Columns, Spacer, Typography, Link } from "@aviary";
import { isEmailValid } from "@shared/components/EmailInput";
import {
  useUnauthUrlFields,
  AnalyticsEvent,
  useAnalyticsLogger,
  useBreakpoints,
} from "@shared/hooks";
import { gRecaptchaExecute } from "@shared/utils";
import { useGlobalConfig } from "@unauthenticated/shared/context";
import { authRoutes } from "@unauthenticated/shared/data/authRoutes";
import { l } from "@unauthenticated/signupSignin/locales/i18n";

import { PatientCheckEmailInput } from "./PatientCheckEmailInput";
import { usePatientInvitationCheckMutation } from "./PatientInvitationCheck.mutation";

import * as styles from "./PatientCheckEmail.styles";

interface Props {
  onEmailSent: (email: string) => void;
  signUpPage: string;
  pageVersion: string;
}

const PatientCheckEmail = ({ onEmailSent, signUpPage, pageVersion }: Props) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [isEmailDirty, setIsEmailDirty] = useState(false);
  const [isEmailExist, setIsEmailExist] = useState(false);
  const { tablet, phoneLarge } = useBreakpoints();
  const { emailRegex } = useGlobalConfig();
  const unauthUrlFields = useUnauthUrlFields();

  const logAnalyticsEvent = useAnalyticsLogger();

  const logCheckPatientInviteLink = (accountExists: boolean) => {
    logAnalyticsEvent({
      eventName: AnalyticsEvent.SIGNUP_CHECK_PATIENT_INVITE,
      payload: {
        ...unauthUrlFields,
        event_message: accountExists ? "AccountWithEmailExists" : "NoAccountExists",
      },
    });
  };

  const [checkInvitation, { loading }] = usePatientInvitationCheckMutation({
    onCompleted: completedData => {
      logCheckPatientInviteLink(completedData?.auth?.patientInvitationCheck?.accountExists);

      if (completedData?.auth?.patientInvitationCheck?.accountExists) {
        setIsEmailExist(true);
      } else {
        setIsEmailExist(false);
        onEmailSent(email);
      }
    },
  });

  const isValid = () => isEmailValid(email, emailRegex) && !isEmailExist;

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    if (!isValid()) {
      setIsEmailDirty(true);

      return;
    }
    gRecaptchaExecute(captchaToken => {
      checkInvitation({
        variables: { input: { captchaToken, attributes: { email, signUpPage, pageVersion } } },
      });
    });
  };

  const setEmailDirty = () => setIsEmailDirty(true);

  const handleChange = ({ target }) => {
    setIsEmailExist(false);
    setEmail(target.value);
  };

  const getEmailExistsError = () => {
    if (isEmailExist) {
      return (
        <Trans i18nKey={l.common.EmailAlreadyExists}>
          An account with this email exists, please continue to {""}
          <Link href={`${authRoutes.login}?email=${encodeURIComponent(email)}`}>sign in</Link>
        </Trans>
      );
    }

    return null;
  };

  return (
    <>
      <Typography css={styles.title} isMarginless type={phoneLarge.greaterThan ? "h3" : "body"}>
        {t(l.practitionerSignUp.EnterEmail)}
      </Typography>
      <Typography {...(phoneLarge.lessThan && { type: "footnote" })} css={styles.text}>
        {t(l.practitionerSignUp.CreateAccount)}
      </Typography>
      <Spacer height="oneHalf" />
      <form css={styles.fullWidth} onSubmit={handleSubmit}>
        <Columns isMarginless isMultiline>
          <Column isPaddingless>
            <PatientCheckEmailInput
              value={email}
              onChange={handleChange}
              setEmail={setEmail}
              required
              isDirty={isEmailDirty}
              onBlur={setEmailDirty}
              isLoading={loading}
              errors={[getEmailExistsError()]}
            />
          </Column>
          <Column
            css={[phoneLarge.greaterThan && styles.marginLeft, styles.marginTop]}
            isPaddingless
            columnWidth="full"
          >
            <Button
              isLoading={loading}
              type="submit"
              disabled={!isValid()}
              size="large"
              isFullWidth={tablet.lessThan}
            >
              {t(l.practitionerSignUp.CheckForInvite)}
            </Button>
          </Column>
        </Columns>
      </form>
    </>
  );
};

export { PatientCheckEmail };
