import { css } from "@emotion/react";

import { dimensions } from "@styles";

export const carouselContainer = css`
  text-align: center;
  @media (max-width: ${dimensions.phoneLargeMax}) {
    height: 6rem;
  }
  @media (max-width: ${dimensions.phoneMax}) {
    height: 7rem;
  }
  @media (max-width: ${dimensions.phoneSmallMax}) {
    height: 9rem;
  }
`;
