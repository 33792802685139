import type { ReactNode } from "react";

import { Column, Columns } from "@aviary";
import { useBreakpoints } from "@shared/hooks";
import { Page } from "@unauthenticated/signupSignin/components/Page";
import { StoreLandingFooter } from "@unauthenticated/signupSignin/components/StoreLandingFooter";

import { PatientInfoBlock } from "../PatientInfoBlock";
import { StoreLandingPageNavBar } from "../StoreLandingPageNavBar";
import { StoreSignupInCarousel } from "../StoreSignupInCarousel";

import * as styles from "./StoreLandingLayout.styles";

interface Props {
  children: ReactNode;
  storeName: string;
  pracAvatar?: string;
  isPreview?: boolean;
  isStoreOwner?: boolean;
}

const StoreLandingLayout = ({
  children,
  storeName,
  pracAvatar,
  isPreview,
  isStoreOwner,
}: Props) => {
  const { phoneLarge } = useBreakpoints();

  return (
    <Page>
      <StoreLandingPageNavBar isPreview={isPreview} isStoreOwner={isStoreOwner} />
      <div css={styles.layoutContainer}>
        <Columns css={styles.columnsContainer}>
          {phoneLarge.greaterThan && (
            <Column columnWidth={6} css={styles.leftColumn}>
              <PatientInfoBlock storeName={storeName} pracAvatar={pracAvatar}>
                <StoreSignupInCarousel />
              </PatientInfoBlock>
            </Column>
          )}
          <Column columnWidth={6} css={styles.rightColumn}>
            {children}
          </Column>
        </Columns>
      </div>
      <StoreLandingFooter />
    </Page>
  );
};

export { StoreLandingLayout };
