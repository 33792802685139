import { css } from "@emotion/react";

import { dimensions } from "@styles";

export const contentBox = css`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  margin-bottom: 1rem;
  text-align: center;
  margin-top: 7.5rem;
  width: 100%;

  @media (max-width: ${dimensions.tablet}) {
    margin-top: 0;
  }
`;

export const tilesContainter = css`
  margin: 4rem 0 !important;
  justify-content: center;
  @media (max-width: ${dimensions.tablet}) {
    & {
      margin: 1.5rem 0 !important;
    }
    div section label div + div {
      margin-right: 0;
    }
  }
`;

export const tileMobile = css`
  @media (max-width: ${dimensions.phone}) {
    padding: 1.5rem 1rem !important;
  }
`;

export const iconSpacing = css`
  margin-top: 1rem;
`;

export const textPadding = css`
  margin: 0.5rem 0;
`;
