import { withProfiler } from "@sentry/react";
import { StrictMode } from "react";

import { LazyHotReloadIndicator, LazyReactAxe } from "@shared/components";
import { RouterProvider, createBrowserRouter } from "@shared/react-router-dom";

import { getEmersonRoutes, getFullscriptRoutes, type Props } from "./routes";

const AuthLandingPage = (props: Props) => {
  const pageVersion = "prac_by_default_with_sso";

  const getRoutes = () => {
    if (props.isEmerson) {
      return getEmersonRoutes({ ...props, pageVersion });
    }

    return getFullscriptRoutes({ ...props, pageVersion });
  };

  return (
    <StrictMode>
      <RouterProvider router={createBrowserRouter(getRoutes())} />
      <LazyHotReloadIndicator />
      <LazyReactAxe />
    </StrictMode>
  );
};

const AuthLandingPageWithProfiler = withProfiler(AuthLandingPage);
export { AuthLandingPageWithProfiler as AuthLandingPage };
