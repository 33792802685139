import { useTranslation } from "react-i18next";

import { Typography } from "@aviary";
import { useLocation, useSearchParams } from "@shared/react-router-dom";
import {
  PatientStoreSignUpForm,
  SignUpHeader,
  SignUpTitle,
  StoreLandingLayout,
  StoreLogo,
} from "@unauthenticated/signupSignin/components";
import type { PatientAttributes } from "@unauthenticated/signupSignin/data/types";
import { l } from "@unauthenticated/signupSignin/locales/i18n";

import * as styles from "./PatientStoreSignUpPage.styles";

interface HistoryState {
  source?: "RX_SIGN_IN";
  email?: string;
}

interface Props {
  patientAttributes: PatientAttributes;
  additionalAttributes?: any;
  pageVersion?: string;
  storeId?: string;
  isPreview?: boolean;
  isStoreOwner: boolean;
  ssoErrors?: string;
}

const PatientStoreSignUpPage = ({
  patientAttributes,
  additionalAttributes,
  pageVersion,
  isPreview = false,
  isStoreOwner,
  storeId,
  ssoErrors,
}: Props) => {
  const { t } = useTranslation();
  const { storeLogo, storeName, pracAvatar, isStoreIntake } = patientAttributes;
  const { state }: { state: HistoryState } = useLocation();
  const [searchParameters] = useSearchParams();

  const isIntake = searchParameters.get("intake") === "true";
  const requestedPractitionerId = searchParameters.get("requestedPractitionerId");

  const renderIntakeSubtitle = () => {
    if (!isStoreIntake || !isIntake) {
      return null;
    }

    return <Typography css={styles.centered}>{t(l.intake.signUpDescription)}</Typography>;
  };

  const renderCreateAccountSubtitle = () => {
    if (isStoreIntake && isIntake) {
      return null;
    }

    if (isSwiftFillSignIn) {
      return (
        <>
          <Typography type="h4">{t(l.auth.createYourAccount)}</Typography>
          <Typography css={styles.centered}>{t(l.auth.swiftfillSignupInstructions)}</Typography>
        </>
      );
    }
  };

  const isSwiftFillSignIn = state?.source === "RX_SIGN_IN";

  return (
    <StoreLandingLayout
      storeName={storeName}
      pracAvatar={pracAvatar}
      isPreview={isPreview}
      isStoreOwner={isStoreOwner}
    >
      <SignUpHeader>
        <StoreLogo storeLogo={storeLogo} storeName={storeName} />
        <SignUpTitle title={storeName} />
        {renderIntakeSubtitle()}
        {renderCreateAccountSubtitle()}
      </SignUpHeader>
      <PatientStoreSignUpForm
        storeId={storeId}
        pageVersion={pageVersion}
        patientAttributes={patientAttributes}
        additionalAttributes={additionalAttributes}
        isPreview={isPreview}
        ssoErrors={ssoErrors}
        preFilledEmail={state?.email}
        isSwiftFillSignIn={isSwiftFillSignIn}
        isIntake={isIntake}
        requestedPractitionerId={requestedPractitionerId}
      />
    </StoreLandingLayout>
  );
};

export { PatientStoreSignUpPage };
