import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Arrange, Button, FloatingLabelInput, Link, Message, Typography } from "@aviary";
import { isEmailValid } from "@shared/components/EmailInput";
import { useUnauthUrlFields } from "@shared/hooks";
import { FontAwesomeIcon } from "@shared/react-fontawesome";
import { useSearchParams } from "@shared/react-router-dom";
import { gRecaptchaExecute } from "@shared/utils";
import { RecaptchaErrorBox } from "@unauthenticated/shared/components";
import { useGlobalConfig } from "@unauthenticated/shared/context";
import { authRoutes } from "@unauthenticated/shared/data/authRoutes";
import {
  useUserSendMagicLinkMutation,
  type UserSendMagicLinkMutationData,
} from "@unauthenticated/shared/data/mutations";
import { l } from "@unauthenticated/signupSignin/locales/i18n";

import * as styles from "./EmailFormContent.styles";

interface Props {
  email: string;
  setEmail: (email: string) => void;
  setPage: (page: "email" | "success") => void;
}

const EmailFormContent = ({ email, setEmail, setPage }: Props) => {
  const { t } = useTranslation();
  const { emailRegex } = useGlobalConfig();
  const [searchParams] = useSearchParams();
  const utmPayload = useUnauthUrlFields({ isCamelCaseKeys: true });
  const [recaptchaError, setRecaptchaError] = useState(false);
  const storeId = searchParams?.get("store_id");
  const redirectPath = searchParams?.get("redirect_path");
  const intake = searchParams?.get("intake") === "true";
  const previousEmail = searchParams.get("email");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (email === undefined && previousEmail) setEmail(previousEmail);
  }, [previousEmail, email, setEmail]);

  const onCompleted = (data: UserSendMagicLinkMutationData) => {
    setIsLoading(false);
    const { message: errorMessage } = data?.auth?.userSendMagicLink?.errors ?? {};

    if (!errorMessage) {
      setPage("success");
    }
  };

  const [sendMagicLink, { loading, data }] = useUserSendMagicLinkMutation({
    onCompleted,
  });

  const handleSendLink = () => {
    setIsLoading(true);

    gRecaptchaExecute(gToken => {
      sendMagicLink({
        variables: {
          input: {
            captchaToken: gToken,
            email,
            intake,
            ...(storeId && { storeId }),
            ...(redirectPath && { redirectPath }),
            ...utmPayload,
          },
        },
      });
    }, setRecaptchaError);
  };

  const handleEmailChange = e => {
    setEmail(e.target.value);
  };

  const { message: errorMessage } = data?.auth?.userSendMagicLink?.errors ?? {};

  return (
    <>
      {!!errorMessage && (
        <Message
          isColor="danger"
          titleContent={t(l.signIn.continueWithoutPassword.error.title)}
          css={styles.errorMessage}
        >
          {errorMessage}
        </Message>
      )}
      {recaptchaError && <RecaptchaErrorBox />}
      <div css={styles.textWrapper}>
        <Typography type="h1">{t(l.signIn.continueWithoutPassword.title)}</Typography>
        <Typography>{t(l.signIn.continueWithoutPassword.description)}</Typography>
        <form onSubmit={handleSendLink}>
          <FloatingLabelInput
            id="instant-link-email-input"
            type="email"
            label="Email"
            value={email}
            handleChange={handleEmailChange}
            required
            wrapperStyles={styles.emailInput}
          />
          <Arrange spacing="xlarge" isVertical alignment="center">
            <Button
              onClick={handleSendLink}
              isLoading={isLoading || loading}
              type="submit"
              disabled={isLoading || loading || !isEmailValid(email, emailRegex)}
            >
              {t(l.signIn.continueWithoutPassword.cta)}
            </Button>
            <Link to={authRoutes.login} isColor="system" isUnderLined={false}>
              <FontAwesomeIcon icon={faChevronLeft} aria-hidden css={styles.chevron} />
              {t(l.signIn.continueWithoutPassword.tryDifferentSignIn)}
            </Link>
          </Arrange>
        </form>
      </div>
    </>
  );
};

export { EmailFormContent };
