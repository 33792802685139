import type { SyntheticEvent } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Arrange, Button, Link, Typography, useToast } from "@aviary";
import FsEeDualLogoIcon from "@shared/assets/images/fs-ee-dual-logo-icon.png";
import { l } from "@unauthenticated/signupSignin/locales/i18n";

import { useOfficeActivateAccount } from "./useOfficeActivateAccount";

import * as styles from "./AuthOfficeActivationForm.styles";

interface Props {
  invitationToken: string;
  setSignUpError: (error: string) => void;
}
const TERMS_OF_SERVICE_URL = "https://fullscript.com/terms-of-service";

const AuthOfficeActivationForm = ({ invitationToken, setSignUpError }: Props) => {
  const { t } = useTranslation();
  const { makeToast } = useToast();
  const { onFormSubmit, loading, data } = useOfficeActivateAccount({ setSignUpError });

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    onFormSubmit({ invitationToken });
  };

  useEffect(() => {
    if (data?.auth?.officeActivateAccount?.errors?.message)
      makeToast("error", data?.auth?.officeActivateAccount?.errors?.message);
  }, [data]);

  return (
    <Arrange isVertical alignment="center" isFullWidth>
      <img src={FsEeDualLogoIcon} alt="dual-icon" />
      <Typography type="h5">{t(l.common.HaveExistingEmersonAccount)} </Typography>
      <Typography type="footnote" css={styles.serviceTerms}>
        {t(l.common.TermsOfServiceWithExistingEmersonAccount)}
        <Link isColor="success" css={styles.linkStyles} href={TERMS_OF_SERVICE_URL} noExternalIcon>
          Terms of Service
        </Link>
      </Typography>
      <form onSubmit={handleSubmit} noValidate>
        <Button
          type="submit"
          isColor="primary"
          isLoading={loading}
          data-e2e="office-sign-up-button"
        >
          {t(l.officeSignUp.ActivateAccount)}
        </Button>
      </form>
    </Arrange>
  );
};

export { AuthOfficeActivationForm };
