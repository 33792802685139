import { Spacer, Skeleton } from "@aviary";
import { SkeletonGoogleSSOButton } from "@shared/components";
import { SkeletonSignInForm, SignInWrapper } from "@unauthenticated/shared/components";
import { SignInLayout } from "@unauthenticated/signupSignin/components";

const SkeletonSignInPage = () => {
  return (
    <SignInLayout>
      <SignInWrapper>
        <Skeleton type="typography" typographySize="h1" />
        <Spacer height="one" />
        <SkeletonGoogleSSOButton isOrTextRendered />
        <Spacer height="one" />
        <SkeletonSignInForm />
        <Spacer height="doubleHalf" />
        <Skeleton type="typography" typographySize="body" isFullWidth />
      </SignInWrapper>
    </SignInLayout>
  );
};

export { SkeletonSignInPage };
