import { useState } from "react";

import { Arrange } from "@aviary";
import { logGaSignupEvent } from "@shared/helpers";
import { hasErrors } from "@shared/helpers/hasErrors";
import {
  useBreakpoints,
  useCallistoPracOnboardingExperiment,
  useSharedGlobalConfig,
} from "@shared/hooks";
import { useSearchParams } from "@shared/react-router-dom";
import type { PractitionerAdditionalAttributesType } from "@shared/types/PractitionerAdditionalAttributesType";
import { gRecaptchaExecute } from "@shared/utils";
import { usePractitionerSignUpMutation } from "@unauthenticated/shared/data/mutations";
import type { PractitionerSignUpMutationData } from "@unauthenticated/shared/data/mutations/PractitionerSignUp.mutation";
import { useLogPractitionerSignUp } from "@unauthenticated/shared/hooks";

import { SignUpCarouselOrFooter } from "../SignUpCarouselOrFooter";

import { SignUpPageSelector } from "./SignUpPageSelector";
import type { SignUpPageType, SimplifySignUpFormData } from "./types";

interface SimplifySignUpProps {
  additionalAttributes?: PractitionerAdditionalAttributesType;
  csuid: string;
  isOAuth?: boolean;
  onCompleted: (data: PractitionerSignUpMutationData) => void;
  ssoErrors?: string;
}

const SimplifySignUp = ({
  additionalAttributes,
  csuid,
  isOAuth,
  onCompleted,
  ssoErrors,
}: SimplifySignUpProps) => {
  const { tablet } = useBreakpoints();
  const pageVersion = "signup_with_t&c";
  const [searchParams, setSearchParams] = useSearchParams();
  const { storePlatform } = useSharedGlobalConfig();
  const { referredFirstName, referredLastName } =
    additionalAttributes?.peerReferralDisplayAttributes || {};

  const isPracOnboardingEnabled = useCallistoPracOnboardingExperiment();

  const [formData, setFormData] = useState<SimplifySignUpFormData>({
    firstName: referredFirstName ?? "",
    lastName: referredLastName ?? "",
    dispensaryName: "",
    practitionerType: null,
    tosTimestamp: "",
    email: "",
    password: "",
    storePlatform,
  });

  const [isAccountExists, setIsAccountExists] = useState(false);
  const [recaptchaError, setRecaptchaError] = useState(false);

  const signUpPage: SignUpPageType = isOAuth ? "oauth_sign_up_page" : "practitioner_sign_up_page";

  const { logField } = useLogPractitionerSignUp({
    eventAggregateId: csuid,
    pageVersion,
    signUpPage,
    storePlatform,
    apiClientId: additionalAttributes?.apiClientId,
  });

  const isPageOneErrors = fields => {
    return fields?.firstName || fields?.lastName || fields?.practitionerTypeId;
  };

  const isPageTwoErrors = fields => {
    return fields?.email;
  };

  const [signUp, { loading, data }] = usePractitionerSignUpMutation({
    onCompleted: payload => {
      if (!hasErrors(payload?.auth?.practitionerSignUp?.errors)) {
        const { id, storeId } = payload?.auth?.practitionerSignUp?.practitioner || {};
        logGaSignupEvent(id, storeId);
      } else if (isPageOneErrors(payload?.auth?.practitionerSignUp?.errors?.fields)) {
        searchParams.set("step", "1");
        setSearchParams(searchParams);
      } else if (isPageTwoErrors(payload?.auth?.practitionerSignUp?.errors?.fields)) {
        // currently the only page two error from BE is duplicate email
        setIsAccountExists(true);
      }
      onCompleted(payload);
    },
  });

  const handleSubmit = () => {
    const { firstName, lastName, email, password, practitionerType, dispensaryName, tosTimestamp } =
      formData;

    const relevantAdditionalAttributesForSignUp = { ...additionalAttributes };

    delete relevantAdditionalAttributesForSignUp?.peerReferralDisplayAttributes;

    gRecaptchaExecute(gToken => {
      signUp({
        variables: {
          input: {
            attributes: {
              firstName,
              lastName,
              storeName: dispensaryName,
              email,
              password,
              practitionerTypeId: practitionerType.id,
              pageVersion,
              signUpPage,
              storePlatform,
              tosAcceptedAt: tosTimestamp,
              optional: relevantAdditionalAttributesForSignUp,
            },
            captchaToken: gToken,
          },
        },
      });
    }, setRecaptchaError);
  };

  return (
    <Arrange
      justify="spaceBetween"
      alignment="center"
      isVertical
      isFullHeight
      isFullWidth={isPracOnboardingEnabled}
      data-testid="simplify-signup"
    >
      <SignUpPageSelector
        errors={data?.auth?.practitionerSignUp?.errors?.fields}
        isLoading={loading}
        formData={formData}
        setFormData={setFormData}
        logField={logField}
        onSubmit={handleSubmit}
        isAccountExists={isAccountExists}
        setIsAccountExists={setIsAccountExists}
        additionalAttributes={additionalAttributes}
        pageVersion={pageVersion}
        signUpPage={signUpPage}
        ssoErrors={ssoErrors}
        recaptchaError={recaptchaError}
      />
      {!isPracOnboardingEnabled && !tablet.greaterThan && (
        <SignUpCarouselOrFooter isWithinViewport={tablet.greaterThan} hideFooterSignIn />
      )}
    </Arrange>
  );
};

export { SimplifySignUp };
