import { ApolloProvider } from "@apollo/client";
import type { ReactNode } from "react";

import { useApolloErrorMessages } from "@shared/data/client";

import { useClient } from "./useClient";

interface Props {
  children: ReactNode;
}

const ApolloClientProvider = ({ children }: Props) => {
  useApolloErrorMessages();
  const client = useClient();

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export { ApolloClientProvider };
