import type { MutableRefObject, FocusEvent, KeyboardEvent } from "react";
import { useState } from "react";

import { useOutsideClick } from "@shared/hooks/useOutsideClick";

const useKeyboardNavigationList = <T extends HTMLElement>(
  numberOfResults: number,
  triggerElementRef: MutableRefObject<T>,
  onOutsideClick: (e: MouseEvent) => void,
  onEnterCallback: () => void,
  onFocusCallback: (e: FocusEvent<HTMLElement>) => void,
  onEscapeCallback?: (e: KeyboardEvent<Element>) => void
) => {
  const [itemSelectedIndex, setItemSelectedIndex] = useState<number>(0);
  useOutsideClick(triggerElementRef, event => {
    setItemSelectedIndex(0);
    onOutsideClick(event);
  });

  const getCircularArrayIndex = (index: number, arrayLength: number) =>
    ((index % arrayLength) + arrayLength) % arrayLength;

  const onKeyDown = (e: KeyboardEvent<Element>) => {
    switch (e.key) {
      case "ArrowDown":
        e.preventDefault();
        setItemSelectedIndex(getCircularArrayIndex(itemSelectedIndex + 1, numberOfResults));
        break;
      case "ArrowUp":
        e.preventDefault();
        setItemSelectedIndex(getCircularArrayIndex(itemSelectedIndex - 1, numberOfResults));
        break;
      case "Enter":
        e.preventDefault();
        setItemSelectedIndex(0);
        if (onEnterCallback) {
          onEnterCallback();
        }
        break;
      case "Escape":
        e.preventDefault();
        setItemSelectedIndex(0);
        if (onEscapeCallback) {
          onEscapeCallback(e);
        }
        break;
      default:
        break;
    }
  };

  const onFocus = (e: FocusEvent<HTMLElement>) => {
    setItemSelectedIndex(0);
    if (onFocusCallback) {
      onFocusCallback(e);
    }
  };

  return { itemSelectedIndex, onKeyDown, onFocus };
};

export { useKeyboardNavigationList };
