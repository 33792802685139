import { useTranslation } from "react-i18next";

import { Link, Typography } from "@aviary";
import { AuthHeader, AuthHeaderWrapper } from "@shared/components";
import { useBreakpoints, useSharedGlobalConfig, useFlippers } from "@shared/hooks";
import { Navigate } from "@shared/react-router-dom";
import type { PractitionerAdditionalAttributesType } from "@shared/types/PractitionerAdditionalAttributesType";
import { SignUpCarouselOrFooter } from "@unauthenticated/shared/components";
import { SimplifySignUp } from "@unauthenticated/shared/components/SimplifySignUp/SimplifySignUp";
import { authRoutes } from "@unauthenticated/shared/data/authRoutes";
import type { PractitionerSignUpMutationData } from "@unauthenticated/shared/data/mutations/PractitionerSignUp.mutation";
import { l } from "@unauthenticated/shared/locales/i18n";
import { PageContent, Page } from "@unauthenticated/signupSignin/components";

import * as styles from "./EmersonSignUpPage.styles";

interface Props {
  onSignInRedirect?: () => void;
  csuid: string;
  additionalAttributes?: PractitionerAdditionalAttributesType;
}

const EmersonSignUpPage = ({ csuid, additionalAttributes }: Props) => {
  const { t } = useTranslation();
  const { tablet } = useBreakpoints();

  const { isUS } = useSharedGlobalConfig();
  const [isEmersonEnabled] = useFlippers("project_lighthouse_eev2");

  if (!isUS || !isEmersonEnabled) return <Navigate to={authRoutes.login} />;

  const handleOnCompleted = (data: PractitionerSignUpMutationData) => {
    if (data.auth.practitionerSignUp.redirectPath) {
      window.location.assign(data.auth.practitionerSignUp.redirectPath);
    }
  };

  const renderSignUp = () => (
    <>
      <AuthHeaderWrapper includeBorder>
        <AuthHeader showFromEmerson />
        {!tablet.lessThan && renderSignInLink()}
      </AuthHeaderWrapper>
      <PageContent>
        <SimplifySignUp
          csuid={csuid}
          onCompleted={handleOnCompleted}
          additionalAttributes={additionalAttributes}
        />
      </PageContent>
    </>
  );

  const renderSignInLink = () => (
    <Typography css={styles.signInText} isMarginless>
      {t(l.common.HaveAcount)}{" "}
      <Link isColor="success" to={authRoutes.emerson.login}>
        {t(l.common.SignIn)}
      </Link>
    </Typography>
  );

  return (
    <Page data-e2e="sign-up-page">
      {renderSignUp()}
      <SignUpCarouselOrFooter isWithinViewport={!tablet.greaterThan} hideFooterSignIn />
    </Page>
  );
};

export { EmersonSignUpPage };
