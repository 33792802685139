import type { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import type { FloatingLabelInputProps } from "@aviary";
import { FloatingLabelInput } from "@aviary";
import { useSharedGlobalConfig } from "@shared/hooks";
import { IsNameValid } from "@unauthenticated/shared/components/NameValidation";

interface Props extends Omit<FloatingLabelInputProps, "id" | "label" | "type"> {
  errors?: ReactNode[];
  isDirty?: boolean;
  value: string;
}

const DispensaryNameInput = ({ isDirty, errors = [], value, ...rest }: Props) => {
  const { isEmerson } = useSharedGlobalConfig();
  const { t } = useTranslation();
  const lPlatform = isEmerson ? "common:Emerson" : "common";

  const getErrors = () => {
    if (!isDirty || !value) return null;

    if (value.length < 4) return [...errors, t(`${lPlatform}:DispensaryNameTooShort`)];

    if (IsNameValid(value)) return null;

    return [...errors, t(`${lPlatform}:DispensaryNameInvalid`)];
  };

  const renderInput = () => (
    <FloatingLabelInput
      id="dispensaryName"
      type="text"
      name="dispensaryName"
      data-e2e="dispensary_name"
      label={t(`${lPlatform}:DispensaryName`)}
      errors={getErrors()}
      disabled={false}
      value={value}
      {...rest}
    />
  );

  return renderInput();
};

export { DispensaryNameInput };
