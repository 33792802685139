import { useSharedGlobalConfig } from "../useSharedGlobalConfig";

import { useExperiment } from "./useExperiment";

/**
 * What is this?
 *
 * useUnauthedExperiment is a tool which returns one of a random set of group names you pass in
 * based on the subject you specify. It is an offshoot of the useExperiment hook making sure your experiment
 * only runs in an unauthenticated context. This is particularly useful when an experiment trigger is in a component shared
 * in both an authenticated an unauthenticated context. When the experiment is called in an authenticated context, the first group
 * will be returned(which should be the control group).The experiment is also not logged to mixpanel or our db in this case.
 *
 * The returned values will be evenly distributed across the subject,
 * meaning you can expect an even 50/50 split if passing in 2 groups,
 * 33/33/33 split for 3 groups etc.
 *
 * Note: The returned value will *always* be the same for a specific analyticsAnonymousId. If that changes the group may change.
 *
 * Note on using for development or staging: You can test this with ExperimentTester. Simply press cmd+e to open the menu.
 */
interface AuthedExperimentOptions<T extends string> {
  /**
   * Name of the experiment.
   */
  experiment: string;
  /**
   * Names of your experiment groups. The useExperiment hook will return a random one of these values
   */
  groups: readonly T[];
  /**
   * Which group to specify always when in an e2e environment (static value).
   * This is useful when building flows and not having to worry about every case for agnostic flows.
   */
  e2eGroup?: T;
  /**
   * Specify whether to log based on any secondary conditions
   * This is useful when you have additional conditionals for add a subject to the experiment.
   */
  shouldLog?: boolean;
}

const useUnauthedExperiment = <T extends string>({
  experiment,
  groups,
  e2eGroup,
  shouldLog = true,
}: AuthedExperimentOptions<T>): T => {
  const { isUnauthenticated } = useSharedGlobalConfig();

  return useExperiment({
    experiment,
    groups,
    e2eGroup,
    authedExperiment: false,
    unauthedExperiment: true,
    shouldLog: isUnauthenticated && shouldLog,
  });
};

export { useUnauthedExperiment };
