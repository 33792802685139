import { common as sharedCommon } from "@unauthenticated/shared/locales/en/US/common";
/* eslint-disable @typescript-eslint/naming-convention */
export const common = {
  ...sharedCommon,
  Patient: "Patient",
  Practitioner: "Practitioner",
  Email: "Email",
  PasswordUpdatedSuccess: "Password has been successfully updated!",
  LoggingYouIn: "Logging you in",
  PasswordsMatch: "Passwords match",
  PasswordPrompt: "Input your password to continue",
  GoToUS: "Go to Fullscript US",
  GoToCA: "Go to Fullscript Canada",
  USLogin: "United States Login Page",
  CALogin: "Canada Login Page",
  USFlag: "US flag icon",
  CAFlag: "Canadian maple leaf icon",
  GoogleLogo: "Google logo",

  // TreatmentPlan Terms
  prescription: "prescription",
  prescription_plural: "prescriptions",
  prescription_capitalized: "Prescription",
  prescription_capitalized_plural: "Prescriptions",
  prescription_past_participle: "prescribed",
  prescription_past_participle_capitalized: "Prescribed",

  // Recommendation Terms
  recommendation: "recommendation",
  recommendation_plural: "recommendations",
  recommendation_capitalized: "Recommendation",
  recommendation_capitalized_plural: "Recommendations",
  recommendation_past_participle: "recommended",
  recommendation_past_participle_capitalized: "Recommended",
} as const;
