import type { ReactNode } from "react";
import { I18nextProvider } from "react-i18next";

import { AviaryProvider } from "@aviary/utils";
import { LoadingProvider, PageViewLogger, NonLazyExperimentTester } from "@shared/components";
import {
  FlippersProvider,
  GlobalContextProvider as SharedGlobalContextProvider,
  GlobalContextUserRole,
} from "@shared/context";
import { createExperimentVar } from "@shared/context/experiment";
import type { Flipper } from "@shared/types/flipper";
import { GlobalContextProvider } from "@unauthenticated/shared/context";
import { ApolloClientProvider } from "@unauthenticated/signupSignin/components/ApolloClientProvider";
import { i18n } from "@unauthenticated/signupSignin/locales";

interface Props {
  treatmentPlanTerm?: string;
  currentCountry: string;
  flippers: Flipper[];
  isEmerson?: boolean;
  e2e: boolean;
  analyticsAnonymousId: string;
  hydrated?: boolean;
  children: ReactNode;
}

const Providers = ({
  children,
  treatmentPlanTerm,
  currentCountry,
  flippers,
  isEmerson,
  e2e,
  analyticsAnonymousId,
  hydrated = true,
}: Props) => {
  createExperimentVar({ analyticsAnonymousId });

  return (
    <FlippersProvider flippers={flippers}>
      <LoadingProvider>
        <ApolloClientProvider>
          <I18nextProvider i18n={i18n}>
            <SharedGlobalContextProvider
              country={currentCountry}
              isEmerson={isEmerson}
              role={GlobalContextUserRole.UNAUTHENTICATED}
              e2e={e2e}
            >
              <GlobalContextProvider treatmentPlanTerm={treatmentPlanTerm}>
                <AviaryProvider isEmerson={isEmerson} isWhiteBackground hydrated={hydrated}>
                  <PageViewLogger isUnauth>{children}</PageViewLogger>
                  <NonLazyExperimentTester />
                </AviaryProvider>
              </GlobalContextProvider>
            </SharedGlobalContextProvider>
          </I18nextProvider>
        </ApolloClientProvider>
      </LoadingProvider>
    </FlippersProvider>
  );
};

export { Providers };
