import { css, type Theme } from "@emotion/react";

export const videoContainer = (theme: Theme) => css`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  background-color: ${theme.surface.overlayBackdrop};
  padding: 0 2rem;
  height: 100vh;
  margin-top: 0;
`;

export const mainPageColumn = css`
  display: flex;
  flex-flow: column;
`;

export const marginRemoved = css`
  margin-top: 0;
  height: 100vh;
`;
